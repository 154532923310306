.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.toolbar-actions {
  display: flex;
  gap: .5rem;
}

.toolbar-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toolbar-search-field {
  margin-left: auto;
}