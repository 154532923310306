.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    margin-top: 9rem;
}

.emoji {
    font-size: 4rem; 
}

.description {
    margin-top: 3rem;
}

.subtext {
    margin-top: 5px;
}