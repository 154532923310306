//
// CSS custom properties
// ------------------------------------------------------------------------------------
:root {
  --update-job-progress-bg-color: var(--goto-neutral-300);
  --update-job-progress-in-progress-gradient-color: rgba(255, 255, 255, .3);
  --update-job-progress-cancelled-gradient: repeating-linear-gradient(-45deg, var(--goto-neutral-200), var(--goto-neutral-200) .25rem, var(--goto-neutral-100) 0, var(--goto-neutral-100) .5rem);

  .theme-dark {
    --update-job-progress-bg-color: var(--goto-neutral-500);
    --update-job-progress-in-progress-gradient-color: rgba(0, 0, 0, .3);
    --update-job-progress-cancelled-gradient: repeating-linear-gradient(-45deg, var(--goto-neutral-500), var(--goto-neutral-500) .25rem, var(--goto-neutral-400) 0, var(--goto-neutral-400) .5rem);
  }
}