.title {
    margin-bottom: 4rem;
}
  
.progress-stepper {
    overflow: hidden;
}
  
  
.actions {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--goto-border-color-default);
    margin: .75rem -1.5rem 0 -1.5rem;
    padding: .75rem 1.5rem 0 1.5rem;
}
  
.actions-right {
    display: flex;
    gap: 1rem;
}
  
  
.error-tooltip {
    font: var(--goto-body-small)
}
  