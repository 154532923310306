@import '../../../styles/variables';

.grid-row-actions {
  display: flex;
  visibility: hidden;
  position: absolute;
  height: calc($row-height - 1px); // because of the row border
  right: .5rem;
  top: -1px; // because of the row border
  background: var(--goto-surface-color-default); // we need this to blend with the hover color (it has alpha)
  z-index: 1;

  > div {
    padding-left: .5rem;
    height: 100%;
    align-items: center;
    display: flex;
    gap: .5rem;
  }
}

:global(.dev-mode) {
  .grid-row-actions.debug {
    visibility: visible;
  }
}

:global(.ag-row-hover),
:global(.row-has-popover) {
  .grid-row-actions {
    visibility: visible;

    > div {
      background: var(--goto-interactive-color-secondary-hover);
    }
  }

  &:global(.ag-row-selected) {
    .grid-row-actions > div {
      background: var(--goto-surface-color-brand-secondary);
    }
  }
}
