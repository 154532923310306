.wrapper {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
}

.empty-state-wrapper {
  padding-top: 7.5rem;
  flex: 1;
  display: grid;
  place-content: center;
}

.progress-wrapper {
  margin: 1rem 0;
}


.first-time-flow-icon {
  fill: var(--goto-icon-color-default);
}