.drawer-icon {
  --goto-icon-color: var(--goto-icon-color-default);
}

.update-info-panel {
  margin-bottom: 1rem;
}

.update-info-title {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;

  span {
    margin-right: .5rem;
  }
}

.loading {
  flex: 1;
  width: 100%;
  display: grid;
  place-content: center;
}

.device-details-tabs {
  height: 100%;
}

.tab-panel {
  padding-top: 8px;
}

.tab-badge {
  font-size: .625rem;
}