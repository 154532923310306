.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .5rem;
}

.title {
  margin-bottom: 2rem;
  display: flex;
}

.badges {
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
}

.alerts {
  margin-bottom: 1rem;
}