@use '../../../styles/mixins' as m;

.tooltip {
  display: flex;
}

.progress-bar {
  display: flex;
  position: relative;
  height: .5rem;
  width: 100%;
  min-width: 8.5rem;

  &.in-progress::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    animation: move 2s linear infinite;
    background-size: 3rem 3rem;
    background-image: linear-gradient(-45deg,
        var(--update-job-progress-in-progress-gradient-color) 25%,
        transparent 25%,
        transparent 50%,
        var(--update-job-progress-in-progress-gradient-color) 50%,
        var(--update-job-progress-in-progress-gradient-color) 75%,
        transparent 75%,
        transparent);
  }

  .progression {
    position: relative;
    display: flex;
    height: 100%;
    background-color: var(--update-job-progress-bg-color);

    &:first-child {
      border-top-left-radius: .125rem;
      border-bottom-left-radius: .125rem;
    }

    &:last-child {
      border-top-right-radius: .125rem;
      border-bottom-right-radius: .125rem;
    }

    &.finished {
      background-color: var(--goto-surface-color-success-default);
    }

    &.failed {
      background-color: var(--goto-surface-color-danger-default);
    }

    &.cancelled {
      background-image: var(--update-job-progress-cancelled-gradient);
    }
  }
}

.status-list {
  @include m.list-unstyled();
  margin: 0;
}

.status-list span + span {
  margin-left: .5em;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 3rem 3rem;
  }
}