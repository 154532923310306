.cell {
  display: flex;
  align-items: center;
  gap: .5rem;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.critical {
  color: #D33A61;
}

.important {
  color: #D33A61;
}

.moderate {
  color: #E77320;
}

.low {
  color: #1B8756;
}
