.devices-cell-container {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between;
}


.chevron-icon {
    --goto-icon-size: 1.2rem;
    margin-left:1rem;
    margin-right:1rem;
}

.update-name-cell-expanded {
    height: 160px;
    padding-top: 22px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.devices-cell-expanded {
    height: 158px;
}

.chevron-cell-expanded {
    height: 158px;
}

// ensures proper custom spacing in chameleon grid between columns whilst also being response
chameleon-data-table-header-cell-v2:first-of-type {
    width: 500px;
}