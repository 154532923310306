.drawer {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 22.5rem;
  transition: all .2s ease-out;
  transform: translate(100%);
  visibility: hidden;
  border-left: 1px solid var(--goto-border-color-default);
  background-color: var(--goto-surface-color-default);

  &:hover {
    .drawer-content {
      overflow-y: scroll;

      &::-webkit-scrollbar-thumb {
        background-color: var(--goto-icon-color-secondary);
      }
    }
  }
}

.drawer-open {
  transform: translate(0);
  visibility: visible;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem .5rem 0 1rem;
  border-bottom: 1px solid var(--goto-border-color-default);
}

.drawer-content {
  flex: 1;
  padding: 1rem .25rem 2rem 1rem;
  margin-right: .25rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: .5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: .25rem;
  }

  // targeting Firefox
  @-moz-document url-prefix() {
    padding-right: .75rem;
    overflow-y: hidden;
  }
}

.drawer-footer {
  padding: 1rem;
  border-top: 1px solid var(--goto-border-color-default);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
