.grid-wrapper  {
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  visibility: hidden;
}

.grid-ready {
  visibility: visible;
}

.grid {
  flex: 1;
}

.grouping-toggle {
  margin-right: var(--goto-spacing-02);
}

.column-header-title {
  font: var(--goto-caption-default-02);
}
