.nav {
  display: flex;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;

  &::before {
    display: inline-block;
    padding-right: .5rem;
    color: var(--goto-text-01);
    font-weight: 700;
    content: '/';
  }
}

.back {
  margin-right: 1rem;
}