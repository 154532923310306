.status-badge {
  margin-left: .5rem;

  &-online, &-in-session {
    color: var(--goto-type-color-success);

  }

  &-offline {
    color: var(--goto-type-color-secondary);
  }
}