.status-icon {
  --goto-icon-size: .5rem;
  padding-right: .25rem;

  &.online, &.in-session {
    --goto-icon-color: var(--goto-icon-color-success);
  }

  &.offline {
    --goto-icon-color: var(--goto-icon-color-disabled);
  }
}
