.grid {
    margin-top: 1rem;
    width: 100%;
}

.checkbox-wrapper {
    width: 100%;
    display: flex;
    background-color: var(--goto-surface-color-default);
    height: 63px;
    align-items: center;
    padding-left: var(--goto-spacing-07);
}

.checkbox-wrapper:hover {
    background-color: var(--goto-surface-color-default);
}

.group-checkbox::part(control) {
    display:none;
}

.header-table-cell-padding {
    padding-left: 0 !important;
}