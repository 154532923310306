.update-button {
    position: absolute;
    right: 0;
    top: 2rem;
}
  
.loading-container {
    width: 100%;
    height: 16rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.chips-container {
    margin-top: 8px;
    padding-top: 8px;
    border-top: 1px solid var(--goto-border-color-default);
    display: flex;
    gap: 8px;
}