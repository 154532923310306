.tabs {
    margin-bottom: 1rem;

    &::part(tablist) {
      padding: 0;
    }
}


.title {
  margin-bottom: 2rem;
}

.device-updates-container { 
  height: 800px;
}