@mixin empty-style() {
  content: '\2013'; // ndash
  display: block;
}

@mixin text-overflow() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin text-overflow-2-lines() {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@mixin overflow-wrap() {
  word-break: break-word;
  overflow-wrap: break-word;
}

@function tint($color, $percent) {
  @return mix(white, $color, $percent);
}

@function shade($color, $percent) {
  @return mix(black, $color, $percent);
}

@mixin list-unstyled() {
  list-style: none;
  padding: 0;
}

@mixin flex-grow-container() {
  display: flex;
  flex-direction: column;
  flex: 1;
}
