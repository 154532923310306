.tooltip-wrapper {
  display: none;
  cursor: default;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.disabled {
  display: block;
}
