.container {
    display: flex;
    gap: var(--goto-spacing-06);

    border-radius: var(--goto-radius-small);
    border: 1px solid var(--goto-border-color-default);
}

.title {
    padding-bottom: var(--goto-spacing-05);
}


.summary-container {
    flex: 2;
    padding-top: var(--goto-spacing-05);
    padding-left: var(--goto-spacing-05);
    padding-bottom: var(--goto-spacing-08);
}

.form-container {
    flex: 1;
    border-left: 1px solid var(--goto-border-color-default);
    padding: var(--goto-spacing-05);
}

.radio-group {
    padding-top: var(--goto-spacing-02);
    padding-bottom: var(--goto-spacing-02);
}

.switch {
    padding-top: var(--goto-spacing-06);
    width: 100%;
}

.schedule {
    padding-top: var(--goto-spacing-05);
    width: 100%;
}

.input {
    padding-top: var(--goto-spacing-02);
}