.title {
  padding-bottom: var(--goto-spacing-02);
}

.body {
  display: flex;
  flex-direction: column;
  gap: var(--goto-spacing-02);
  align-items: center;
}
