.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    // color: black;
    margin-top: 9rem;
}

.description {
    margin-top: 8px;
}

.subtext {
    margin-top: 5px;
}