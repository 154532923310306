.cell,
.tooltip-trigger {
  display: flex;
  align-items: center;
  gap: .25rem;
}

.pending {
  --goto-icon-color: var(--goto-icon-color-secondary);
}

.running {
  --goto-icon-color: var(--goto-icon-color-brand-default);
}

.finished {
  --goto-icon-color: var(--goto-icon-color-success);
}

.cancelled {
  --goto-icon-color: var(--goto-icon-color-disabled);
}

.failed {
  --goto-icon-color: var(--goto-icon-color-danger);
}
