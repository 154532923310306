.update-drawer {
  display: flex;
  flex-direction: column;
  row-gap: var(--goto-spacing-07);

  & section {
    display: flex;
    flex-direction: column;
    row-gap: var(--goto-spacing-03);
  }

  chameleon-switch {
    width: 100%;
  }

  chameleon-switch::part(label) {
    flex-grow: 1;
  }

  chameleon-switch::part(status-message) {
    display: none;
  }
}

.switch-section-title {
  display: flex;
  justify-content: space-between;
}

.update-info-icon {
  --goto-icon-color: var(--goto-icon-color-default);
}

.update-info-section:not(:last-child) {
  margin-bottom: 1.5rem;
}

.update-info-section-title {
  display: flex;
  align-items: center;
  margin-bottom: .25rem;

  span {
    margin-right: .5rem;
  }
}

.update-info-section-entry:not(:last-child) {
  margin-bottom: 1rem;
}

.update-info-section-heading {
  margin-top: 1rem;
}
