.create-ticket-form {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--goto-spacing-06);

  &-alert {
    padding-top: var(--goto-spacing-01);
  }

  &-details {
    white-space: pre-line;
    padding-top: var(--goto-spacing-02);
  }

  &-subject {
    padding-top: var(--goto-spacing-04);
  }

  &-summary {
    padding-top: var(--goto-spacing-02);

    .summary-form-control::part(control) {
      min-height: 7.5rem;
    }
  }

  &-select {
    padding-top: var(--goto-spacing-06);
  }

  &-buttons {
    display: flex;
    flex-direction: row-reverse;
    align-content: center;
    padding: 1.5rem 0 0 0;
    gap: .5rem
  }
}

.loading {
  width: 100%;
  display: grid;
  place-content: center;
  height: 36.1875rem;
}
