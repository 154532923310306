.actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.action-item {
  display: flex;
  align-items: center;
}
