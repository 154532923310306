@import '~@ag-grid-community/core/dist/styles/ag-grid.scss';
@import '~@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss';
@import '~@ag-grid-community/core/dist/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin.scss';
@import 'variables';

// Ag-Grid overrides
.ag-row-group {
  .ag-cell-wrapper {
    display: inline-grid;
    grid-auto-columns: auto;
  }

  .ag-group-expanded,
  .ag-group-contracted {
    grid-row: 1;
    grid-column: 4;
    margin-left: .5rem;
  }

  .ag-group-checkbox {
    grid-row: 1;
    grid-column: 1;
    margin-left: .4375rem;
  }

  .ag-group-value {
    grid-row: 1;
    grid-column: 2;
    font-size: 1rem;
    font-weight: 700;
  }

  .ag-group-child-count {
    grid-row: 1;
    grid-column: 3;
    font-size: 1rem;
    font-weight: 700;
  }
}

@mixin chameleon-overrides {
  .ag-root-wrapper {
    border-radius: var(--goto-radius-small);
  }

  .ag-icon-asc,
  .ag-icon-desc {
    color: transparent;
    background-color: var(--goto-type-color-brand-default);
  }

  .ag-icon-asc {
    // css loader and webpack dont like each other, so we had to inline the svg
    // mask-image: url('~@getgo/chameleon-icons/dist/rebranded/chevron-up-filled.svg');
    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' role='img'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.29291 9.29291L6.70712 7.87869L12 13.1716L17.2929 7.87869L18.7071 9.29291L12 16L5.29291 9.29291Z' fill='currentColor'%3E%3C/path%3E%3C/svg%3E");
  }

  .ag-icon-desc {
    // mask-image: url('~@getgo/chameleon-icons/dist/rebranded/chevron-down-filled.svg');
    mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' role='img'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.29291 9.29291L6.70712 7.87869L12 13.1716L17.2929 7.87869L18.7071 9.29291L12 16L5.29291 9.29291Z' fill='currentColor'%3E%3C/path%3E%3C/svg%3E");
  }

  .ag-checkbox {
    margin-left: -.5rem;

    .ag-checkbox-input-wrapper {
      border: 1px solid var(--goto-border-color-default);

      &.ag-checked,
      &.ag-indeterminate {
        background-color: var(--goto-interactive-color-brand-default);
        border-color: var(--goto-interactive-color-brand-default);

        &:hover {
          background-color: var(--goto-interactive-color-brand-hover);
          border-color: var(--goto-interactive-color-brand-hover);
        }
      }

      &:active,
      &:focus-within {
        // remove default box shadow
        box-shadow: none;
      }

      &:focus-visible {
        // add Chameleon box-shadow
        box-shadow: var(--goto-highlight);
      }
    }

    .ag-checkbox-input-wrapper::after {
      content: '';
      width: 1.25rem;
      height: 1.25rem;
      transform: translate(-1px, -1px)
    }

    .ag-checkbox-input-wrapper {
      &.ag-checked::after {
        background-color: var(--goto-text-06);
        // mask-image: url('~@getgo/chameleon-icons/dist/rebranded/checkbox-check.svg');
        mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' role='img'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.7071 8.70711L10.5 16.9142L6.29289 12.7071L7.70711 11.2929L10.5 14.0858L17.2929 7.29289L18.7071 8.70711Z' fill='currentColor'%3E%3C/path%3E%3C/svg%3E");
      }

      &.ag-indeterminate::after {
        background-color: var(--goto-text-06);
        // mask-image: url('~@getgo/chameleon-icons/dist/rebranded/checkbox-minus-filled');
        mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' role='img'%3E%3Cpath d='M6 11H18V13H6V11Z' fill='currentColor'%3E%3C/path%3E%3C/svg%3E");
      }
    }

    .ag-checkbox-input-wrapper.ag-disabled {
      border: none;
      border-radius: var(--goto-radius-small);
      background-color: var(--goto-interactive-color-disabled)
    }
  }

  .ag-tooltip {
    transition: none;
    border: none;
    box-shadow: none;
    background: var(--goto-neutral);
    color: var(--goto-text-04);
    padding: var(--goto-spacing-02);
    border-radius: var(--goto-radius-small);
    max-width: 12.5rem;
  }

  .ag-row {
    // remove ag-row hover transition to make the row hover background and the actions appear simultaneously
    transition: none;
    font-size: .75rem;

    &:not(.ag-row-selected) {

      &.ag-row-hover,
      &.row-has-popover {
        background: var(--goto-interactive-color-secondary-hover);
      }
    }

    .ag-cell-wrapper {
      height: 100%;
    }

    .ag-cell {
      padding-left: .5rem;
      padding-right: .5rem;

      &[aria-colindex="1"] {
        padding-left: 2.375rem;
      }
    }
  }

  .ag-header {
    font-size: .625rem;

    .preferred-device-header-cell {
      --goto-icon-color: var(--goto-type-color-default);
      --goto-icon-size: 1rem;
    }

    .ag-cell-label-container {
      width: fit-content;
    }

    .ag-header-cell-text {
      font-family: var(--goto-caption-medium-strong);
      color: var(--goto-type-color-default);
    }

    .ag-header-cell {
      padding: 0;

      &[aria-colindex="1"] {
        padding-left: 1.5rem;
      }

      &:not(.ag-right-aligned-header) {
        .ag-header-label-icon {
          margin-left: 0;
        }
      }

      .ag-header-label-icon {
        margin-left: 0;
      }

      .ag-sort-indicator-icon {
        padding: 0;
      }

      .ag-header-cell-label {
        padding: .25rem .5rem;
      }

      .ag-header-cell-sorted-desc,
      .ag-header-cell-sorted-asc {
        .ag-header-cell-label {
          padding: .25rem .5rem;
          background-color: var(--goto-surface-color-brand-secondary);
          border-radius: var(--goto-radius-medium);

          .ag-header-cell-text {
            color: var(--goto-type-color-brand-default);
          }
        }
      }
    }
  }
}

.ag-paging-panel {
  justify-content: flex-start;
}

.ag-theme-chameleon {
  @include ag-theme-alpine((disabled-foreground-color: $text-color-03-light,
      header-foreground-color: $text-color-02-light,
      font-family: var(--goto-datatable-cell-font),
      header-cell-hover-background-color: transparent,
      border-color: $border-color-light,
      border-radius: var(--goto-radius-small),
      row-border-color: $border-color-light,
      icon-size: 20px,
      row-hover-color: null,
      header-background-color: $surface-color-default-light,
      background-color: $surface-color-default-light,
      odd-row-background-color: $surface-color-default-light,
      selected-row-background-color: $surface-color-secondary-light));
  @include chameleon-overrides();
}

.ag-theme-chameleon-dark {
  @include ag-theme-alpine-dark((disabled-foreground-color: $text-color-03-dark,
      header-foreground-color: $text-color-02-dark,
      font-family: var(--goto-datatable-cell-font),
      header-cell-hover-background-color: transparent,
      border-color: $border-color-dark,
      border-radius: var(--goto-radius-small),
      row-border-color: $border-color-dark,
      icon-size: 20px,
      row-hover-color: null,
      header-background-color: $surface-color-default-dark,
      background-color: $surface-color-default-dark,
      odd-row-background-color: $surface-color-default-dark,
      selected-row-background-color: $surface-color-secondary-dark));
  @include chameleon-overrides();
}