.page-content {
  display: grid;
  grid-template-columns: 26rem 1fr;
  gap: 1.5rem;
  flex: 1;
}

.summary {
  padding: 1rem;
  border: 1px solid var(--goto-border-color-default);
  border-radius: var(--goto-radius-small);
  background-color: var(--goto-surface-color-secondary);
}

.summary-title {
  margin-bottom: 1.25rem;
}

.update-job-progress {
  margin-bottom: 1rem;
}

.update-job-details {
  margin-bottom: 1rem;
}

.description-item {
  display: flex;
}

.description-item-label {
  min-width: 7rem;
  white-space: nowrap;
}

.update-info-section:not(:last-child) {
  margin-bottom: 1.5rem;
}

.update-info-section-title {
  display: flex;
  align-items: center;
  margin-bottom: .25rem;

  span {
    margin-right: .5rem;
  }
}

.update-info-section-entry:not(:last-child) {
  margin-bottom: 1rem;
}
