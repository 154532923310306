.page-title {
  margin-bottom: 2rem;
}

.install-job-display-name {
  margin-bottom: 1.5rem;
}

.install-info-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: .5rem;
  margin-top: .7rem;
}

.install-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

.install-info-icon {
  margin-right: .5rem;
  align-self: center;
  --goto-icon-color: var(--goto-icon-color-default);
  --goto-icon-size: 1.375rem;
}

.update-info-panel {
  margin-bottom: 1rem;
}

.update-info-icon {
  --goto-icon-color: var(--goto-icon-color-default);
}

.update-info-section:not(:last-child) {
  margin-bottom: 1.5rem;
}

.update-info-section-title {
  display: flex;
  align-items: center;
  margin-bottom: .25rem;

  span {
    margin-right: .5rem;
  }
}

.update-info-section-entry:not(:last-child) {
  margin-bottom: 1rem;
}

.install-optional-updates-switch {
  flex-direction: row-reverse;
  margin-left: calc(var(--goto-spacing-02) * -1);
}
