.deviceUpdateSummaryCell {
  display: flex;
  flex-direction: column;
  row-gap: .1em;
  line-height: normal;
}

.deviceUpdateSummaryStatusHint {
  display: flex;
  margin-bottom: -.25em;
  & *+* {
    margin-left: .25em;
  }
}