@use '../../../styles/mixins' as m;

.snackbar-wrapper {
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: 1;

  .custom-snackbar > h2 {
    @include m.text-overflow-2-lines()
  }
}

