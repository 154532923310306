@use '../../../../../styles/mixins' as m;

.device-name-link {
  display: block;

  &::part(control) {
    @include m.text-overflow();
    display: block;
    padding: 0;
  }
}
