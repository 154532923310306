.actions {
    visibility: hidden;
    position: relative;
  }
  
  .action-items-wrapper {
    background-color: var(--goto-surface-color-default);
    position: absolute;
    right: 0;
    transform: translateY(-50%);
  }
  
  .action-items {
    padding-left: .5rem;
    display: flex;
    gap: .5rem;
  }
  
  .actions-debug {
    visibility: visible;
  }
  
  .row {
    &:hover:not(:active) {
      .actions {
        visibility: visible;
      }
  
      .action-items {
        background-color: var(--goto-interactive-color-secondary-hover);
      }
    }
  
    &:active {
      .actions {
        visibility: visible;
      }
  
      .action-items {
        background-color: var(--goto-interactive-color-secondary-pressed);
      }
    }
  }
  
  .icon {
    --goto-icon-size: 1.25rem;
  }

.loading-container {
  width: 100%;
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}