.cell {
  display: flex;
  align-items: center;
  gap: .5rem;

  .text {
    // Typography Chameleon component uses line-clamp to break text to set number of lines
    &::part(text) {
      line-height: 1.25;
    }
  }

  &-icon {
    --goto-icon-size: 1.25rem;
  }

  &-up-to-date {
    --goto-icon-color: var(--goto-icon-color-success);
  }

  &-updates-available {
    --goto-icon-color: var(--goto-icon-color-brand-default);
  }

  &-vulnerable {
    --goto-icon-color: var(--goto-icon-color-danger);
  }

  &-limited {
    --goto-icon-color: var(--goto-icon-color-warning);
  }

  &-error {
    --goto-icon-color: var(--goto-icon-color-secondary);
  }

  &-unknown {
    --goto-icon-color: var(--goto-icon-color-secondary);
  }
}
