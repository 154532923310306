.spinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.goto-icon-color {
  --goto-icon-color: var(--goto-icon-color-brand-default);
}